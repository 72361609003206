<template>
  <b-tab>
    <template #title>
      <feather-icon
          icon="BookOpenIcon"
          size="21"
      />
      <strong>
        Foglalások
      </strong>
    </template>

    <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-row>
        <b-col cols="12">
          <reservation-list ref="todo" :only-todo="true" :user-id="entityId"></reservation-list>
        </b-col>
      </b-row>
    </b-overlay>
  </b-tab>
</template>

<script>
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupText,
  BOverlay,
  BForm,
  BLink,
} from 'bootstrap-vue'
import ReservationList from "../../../../reservation/List.vue"

export default {
  components: {
    BTab,
    BBadge,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupText,
    BOverlay,
    BForm,
    BLink,
    ReservationList
  },
  props: {
    entityId: {
      type: Number,
      required: false
    },
  },
  data() {
    return {
      loading: false,
    }
  }
}
</script>
