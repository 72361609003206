const labels = {
  hu: {
    labelPrevDecade: 'Előző évtized',
    labelPrevYear: 'Előző év',
    labelPrevMonth: 'Előző hónap',
    labelCurrentMonth: 'Aktuális hónap',
    labelNextMonth: 'Következő hónap',
    labelNextYear: 'Következő év',
    labelNextDecade: 'Következő évtized',
    labelToday: 'Ma',
    labelSelected: 'Sikeres jelölés',
    labelNoDateSelected: 'Válassz dátumot',
    labelCalendar: 'Naptár',
    labelNav: 'Naptár navigáció',
    labelHelp: '',
    labelButtonToday: 'Ma',
    labelButtonReset: 'Törlés',
    labelButtonClose: 'Bezárás',
  },
}

export default labels
