<template>
    <b-tab>
        <template #title>
            <feather-icon
                    icon="MessageSquareIcon"
                    size="21"
            />
            <strong>
                Üzenetek
            </strong>
        </template>

        <b-overlay
                :show="loading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
        >
            <b-row>
                <b-col cols="12">
                    <vue-good-table
                            mode="remote"
                            :columns="columns"
                            :rows="rows"
                            :rtl="direction"
                            :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
                            @on-row-click="onRowClick"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                    >
                        <div slot="emptystate">
                            <div class="vgt-center-align vgt-text-disabled">
                                {{ $t('table.list.empty') }}
                            </div>
                        </div>
                        <template
                                slot="table-row"
                                slot-scope="props"
                        >

                            <!-- Column: Name -->
                            <span
                                    v-if="props.column.field === 'subject'"
                                    class="text-nowrap"
                            >
                <span class="text-nowrap">{{ props.row.generatedSubject }}</span>
              </span>

                            <!-- Column: Common -->
                            <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
                        </template>

                        <!-- pagination -->
                        <template
                                slot="pagination-bottom"
                                slot-scope="props"
                        >
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                                    <b-form-select
                                            v-model="pageLength"
                                            :options="pages"
                                            class="mx-1"
                                            @change="handlePageChange"
                                    />
                                    <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                                </div>
                                <div>
                                    <b-pagination
                                            :value="1"
                                            :total-rows="dataFromServer.total"
                                            :per-page="dataFromServer.per_page"
                                            first-number
                                            last-number
                                            align="right"
                                            prev-class="prev-item"
                                            next-class="next-item"
                                            class="mt-1 mb-0"
                                            @change="handleChangePage"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                    icon="ChevronLeftIcon"
                                                    size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                    icon="ChevronRightIcon"
                                                    size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-overlay>
    </b-tab>
</template>

<script>
import {
    BTab,
    BOverlay,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'

export default {
    components: {
        VueGoodTable,
        BTab,
        BOverlay,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BRow,
        BCol,
    },
    props: {
        entityId: {
            type: Number,
            required: false
        },
    },
    data() {
        return {
            role: 'message',
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: this.$t('form.id'),
                    field: 'id',
                    sortable: false,
                    filterOptions: {
                        enabled: false,
                    },
                    width: '5%',
                },
                {
                    label: this.$t('form.subject'),
                    field: 'subject',
                    sortable: false,
                    filterOptions: {
                        enabled: false,
                    },
                },
                {
                    label: 'Utolsó üzenet ideje',
                    field: 'LastMessageAt',
                    sortable: false,
                    formatFn: this.$formatters.formatLaravelTimestampToDateTime,
                },
            ],
            rows: [],
            page: 1,
            dataFromServer: [],
            filterData: [],
            searchTerm: '',
            sortBy: {
                field: 'LastMessageAt',
                type: 'desc',
            },
            columnFilters: [],
            loading: false
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        this.initEntities()
    },
    methods: {
        initEntities() {
            this.loading = true

            if (this.entityId && this.entityId > 0) {
                this.columnFilters = {
                    chat_partner_id: this.entityId,
                }
            }

            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: this.columnFilters,
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchMessageGroups', searchParams).then(response => {
                this.rows = response.data.entities
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            }).finally(() => {
                this.loading = false
            })
        },
        onRowClick(params) {
            this.$router.push({name: 'messages', query: {id: params.row.id}})
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        globalSearch(params) {
            this.page = 1
            this.query = params.searchTerm
            this.initEntities()
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initEntities()
        },
        onColumnFilter(params) {
            this.page = 1
            this.columnFilters = params.columnFilters
            this.initEntities()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
