<template>
    <div>
        <div class="custom-search d-flex justify-content-end mb-1" v-if="!userId">
            <div class="float-left mr-auto">
                <b-button
                        variant="primary"
                        @click="newEntity"
                >
                    <span class="text-nowrap">Hozzáadás</span>
                </b-button>
            </div>
        </div>

        <b-alert
                v-if="!userId"
                variant="warning"
                :show="toBeApproved > 0"
                class="mb-2"
        >
            <div class="alert-body">
                <feather-icon
                        icon="AlertTriangleIcon"
                        class="mr-50"
                />
                {{ toBeApproved }} galéria vár jóváhagyásra (publikálásra).<br>
                <b-button
                        class="mt-2"
                        size="sm"
                        variant="warning"
                        @click.stop="listToBeApproved"
                >Listázás
                </b-button>
            </div>
        </b-alert>

        <b-overlay
                :show="loading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
        >
            <!-- table -->
            <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction"
                    :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
                    :sort-options="{
            enabled: true,
            initialSortBy: sortBy
          }"
                    @on-row-click="onRowClick"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
            >
                <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">
                        {{ loading ? 'Adatok betöltése ...' : $t('table.list.empty') }}
                    </div>
                </div>
                <template
                        slot="table-row"
                        slot-scope="props"
                >

                    <!-- Column: Name -->
                    <span
                            v-if="props.column.field === 'title'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.title }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'user_id'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap" v-if="props.row.user_id === null">Maconka</span>
                <span class="text-nowrap" v-else>{{ props.row.user.name }} ({{ props.row.user_id }})</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'is_public'"
                            class="text-nowrap"
                    >
                <span v-if="props.row.is_public" class="text-nowrap">Igen</span>
                <span v-else class="text-nowrap">Nem</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'is_approved'"
                            class="text-nowrap"
                    >
                <span v-if="props.row.is_approved" class="text-nowrap">Igen</span>
                <span v-else class="text-nowrap">Nem</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'show_in_home_page'"
                            class="text-nowrap"
                    >
                <span v-if="props.row.show_in_home_page" class="text-nowrap">Igen</span>
                <span v-else class="text-nowrap">Nem</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'visible'"
                            class="text-nowrap"
                    >
                <span v-if="props.row.visible" class="text-nowrap">Igen</span>
                <span v-else class="text-nowrap">Nem</span>
              </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                <span v-if="$acl.canEditPermission(role)">
                  <b-button
                          size="sm"
                          variant="primary"
                          @click.stop="$router.push({ name: 'modify-gallery', params: { id: props.row.id }})"
                  >
                    <feather-icon
                            icon="EditIcon"
                    />
                  </b-button>
                </span>
                <span v-if="$acl.canDeletePermission(role)">
                  <b-button
                          size="sm"
                          variant="danger"
                          class="ml-1"
                          @click.stop="deleteEntity(props.row.id, $event)"
                  >
                    <feather-icon
                            icon="XCircleIcon"
                    />
                  </b-button>
                </span>
              </span>

                    <!-- Column: Common -->
                    <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
                </template>

                <!-- pagination -->
                <template
                        slot="pagination-bottom"
                        slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                            <b-form-select
                                    v-model="pageLength"
                                    :options="pages"
                                    class="mx-1"
                                    @change="handlePageChange"
                            />
                            <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                        </div>
                        <div>
                            <b-pagination
                                    :value="1"
                                    :total-rows="dataFromServer.total"
                                    :per-page="dataFromServer.per_page"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @change="handleChangePage"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-overlay>
    </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BAlert,
    BOverlay
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BAlert,
        BOverlay,
    },
    props: {
        userId: {
            type: Number
        }
    },
    data() {
        return {
            role: 'gallery',
            loading: false,
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: this.$t('form.id'),
                    field: 'id',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Az. szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Tulajdonos',
                    field: 'user_id',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Tulajdonos szerinti szűrés'
                    },
                },
                {
                    label: this.$t('form.title'),
                    field: 'title',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Név szerinti szűrés',
                    },
                },
                {
                    label: 'Nyilvános',
                    field: 'is_public',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Nyilvános szerinti szűrés',
                        filterDropdownItems: [
                            {value: true, text: 'Igen'},
                            {value: false, text: 'Nem'}
                        ]
                    },
                },
                {
                    label: 'Publikált',
                    field: 'is_approved',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Publikált szerinti szűrés',
                        filterDropdownItems: [
                            {value: true, text: 'Igen'},
                            {value: false, text: 'Nem'}
                        ]
                    },
                },
                {
                    label: 'Jóváhagyott',
                    field: 'visible',
                    sortable: false,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Jóváhagyott szerinti szűrés',
                        filterDropdownItems: [
                            {value: true, text: 'Igen'},
                            {value: false, text: 'Nem'}
                        ]
                    },
                },
                {
                    label: 'Főoldalon megjelenik',
                    field: 'show_in_home_page',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Főoldalon megjelenik szerinti szűrés',
                        filterDropdownItems: [
                            {value: true, text: 'Igen'},
                            {value: false, text: 'Nem'}
                        ]
                    },
                },
                {
                    label: 'Képek száma',
                    field: 'imagesCount',
                    sortable: false,
                    filterOptions: {
                        enabled: false,
                    },
                },
                {
                    label: this.$t('form.created_at'),
                    field: 'created_at',
                    sortable: false,
                    formatFn: this.$formatters.formatLaravelTimestampToDateTime,
                },
            ],
            rows: [],
            page: 1,
            sortBy: {
                field: 'created_at',
                type: 'desc',
            },
            dataFromServer: [],
            toBeApproved: 0,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        if (this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
            const actions = [
                {
                    label: this.$t('table.header.actions'),
                    field: 'action',
                    sortable: false,
                    width: '15rem',
                },
            ]

            this.columns = [...this.columns, ...actions]
        }

        this.initEntities()
    },
    methods: {
        initEntities() {
            this.loading = true

            if (this.userId && this.userId > 0) {
                this.columnFilters = {
                    user_id: this.userId,
                }
                this.$set(this.columns[1], 'hidden', true)
            }


            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: this.columnFilters,
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchGalleries', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            }).finally(() => {
                this.loading = false
            })

            this.$store.dispatch('getToBeApprovedCount').then(response => {
                this.toBeApproved = response.data
            })
        },
        onRowClick(params) {
            if (this.$acl.canEditPermission(this.role))
                this.$router.push({name: 'modify-gallery', params: {id: params.row.id}})
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        newEntity() {
            this.$router.push({name: 'add-gallery'})
        },
        deleteEntity(id, event) {
            this.$bvModal
                .msgBoxConfirm('Biztosan törölni szeretnéd a galériát?', {
                    title: 'Jóváhagyás',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Igen',
                    cancelTitle: 'Nem',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        // axios
                        this.$store.dispatch('deleteGallery', id).then(() => {
                            this.initEntities(1)
                            this.$helpers.showSuccessToast(`Sikeres törlés (${id})`)
                        })
                    }
                })
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initEntities()
        },
        onColumnFilter(params) {
            this.page = 1
            this.columnFilters = params.columnFilters
            this.initEntities()
        },
        listToBeApproved() {
            this.columnFilters = {
                is_public: "true",
                is_approved: "false"
            }
            this.initEntities()
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
