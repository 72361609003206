<template>
    <div>
        <b-overlay
                :show="loading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
        >
            <div class="custom-search d-flex justify-content-end mb-1">
                <div class="float-left mr-auto" v-if="!onlyTodo || Boolean(onlyTodo) !== true">
                    <b-button
                            variant="primary"
                            @click="newEntity"
                    >
                        <span class="text-nowrap">Hozzáadás</span>
                    </b-button>
                </div>
                <div class="float-right ml-auto">
                    <b-button v-if="!onlyTodo && isTableFiltered"
                              class="mr-2"
                              variant="warning"
                              @click="resetFilters"
                    >
          <span class="text-nowrap">
            <feather-icon icon="DeleteIcon"/> Szűrők alaphelyzetbe állítása
          </span>
                    </b-button>
                    <b-button
                            :variant="(filterVisible) ? 'danger' : 'success'"
                            @click="toggleFilters"
                    >
          <span v-if="filterVisible" class="text-nowrap">
            <feather-icon icon="EyeOffIcon"/> Szűrők elrejtése
          </span>
                        <span v-else class="text-nowrap">
            <feather-icon icon="EyeIcon"/> Szűrők megjelenítése
          </span>
                    </b-button>
                </div>
            </div>

            <b-collapse
                    id="filter-block"
                    v-model="filterVisible"
                    class="mt-2"
            >
                <b-card title="Szűrők">
                    <b-row>
                        <!-- month -->
                        <b-col
                                cols="4"
                        >
                            <b-form-group
                                    label="Hónap"
                            >
                                <v-select
                                        v-model="filters.month"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="monthOptions"
                                        placeholder="Kérem válasszon!"
                                        :clearable="true"
                                        @input="monthInputChanged"
                                >
                                    <span slot="no-options">{{ $t('label.emptySelect') }}</span>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <!-- date from -->
                        <b-col
                                cols="4"
                        >
                            <b-form-group
                                    label="Intervallum kezdete"
                            >
                                <b-form-datepicker
                                        v-model="filters.dateFrom"
                                        :locale="$i18n.locale"
                                        v-bind="labels[$i18n.locale] || {}"
                                        today-button
                                        :label-today-button="labels[$i18n.locale].labelButtonToday || 'Today'"
                                        reset-button
                                        :label-reset-button="labels[$i18n.locale].labelButtonReset || 'Reset'"
                                        close-button
                                        :label-close-button="labels[$i18n.locale].labelButtonClose || 'Close'"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- date to -->
                        <b-col
                                cols="4"
                        >
                            <b-form-group
                                    label="Intervallum vége"
                            >
                                <b-form-datepicker
                                        v-model="filters.dateTo"
                                        :locale="$i18n.locale"
                                        v-bind="labels[$i18n.locale] || {}"
                                        today-button
                                        :label-today-button="labels[$i18n.locale].labelButtonToday || 'Today'"
                                        reset-button
                                        :label-reset-button="labels[$i18n.locale].labelButtonReset || 'Reset'"
                                        close-button
                                        :label-close-button="labels[$i18n.locale].labelButtonClose || 'Close'"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mb-1">
                        <b-col
                                cols="12"
                        >
                            <b-button
                                    class="float-right"
                                    variant="success"
                                    size="sm"
                                    @click="initEntities"
                            >
                                Keresés
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-collapse>
            <!-- table -->
            <vue-good-table
                    ref="reservationsTable"
                    mode="remote"
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction"
                    :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
                    :sort-options="{
            enabled: true,
            initialSortBy: sortBy
          }"
                    @on-row-click="onRowClick"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
            >
                <div slot="emptystate">
                    <div class="vgt-center-align vgt-text-disabled">
                        {{ loading ? 'Adatok betöltése ...' : $t('table.list.empty') }}
                    </div>
                </div>
                <template
                        slot="table-row"
                        slot-scope="props"
                >

                    <!-- Column: Name -->
                    <span
                            v-if="props.column.field === 'fishing_spot_id'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.fishingSpotName }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'user_id'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.name }}</span>
              </span>

                    <span
                            v-else-if="props.column.field === 'start_date'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.start_date }} <br/><small>{{ props.row.planned_arrival }}</small></span>
              </span>

                    <span
                            v-else-if="props.column.field === 'end_date'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ props.row.end_date }} <br/><small>{{ props.row.planned_departure }}</small></span>
              </span>

                    <span
                            v-else-if="props.column.field === 'status'"
                            class="text-nowrap"
                    >
                <span class="text-nowrap">{{ $t("statuses." + props.row.status) }}</span>
              </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                <span v-if="$acl.canListPermission(role)">
                  <b-button
                          size="sm"
                          variant="info"
                          @click.stop="$router.push({ name: 'show-reservation', params: { id: props.row.id }})"
                  >
                    <feather-icon
                            icon="EyeIcon"
                    />
                  </b-button>
                </span>
              </span>

                    <!-- Column: Common -->
                    <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
                </template>

                <!-- pagination -->
                <template
                        slot="pagination-bottom"
                        slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                            <b-form-select
                                    v-model="pageLength"
                                    :options="pages"
                                    class="mx-1"
                                    @change="handlePageChange"
                            />
                            <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                        </div>
                        <div>
                            <b-pagination
                                    :value="1"
                                    :total-rows="dataFromServer.total"
                                    :per-page="dataFromServer.per_page"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @change="handleChangePage"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-overlay>
    </div>
</template>

<script>
import {
    BAlert,
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BOverlay,
    BRow
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import vSelect from 'vue-select'
import labels from '@/libs/i18n/datepicker/labels'
import Ripple from 'vue-ripple-directive'

export default {
    props: {
        onlyTodo: {
            type: Boolean
        },
        userId: {
            type: Number
        }
    },
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BAlert,
        BCollapse,
        BFormDatepicker,
        BRow,
        BCol,
        BCard,
        BOverlay,
        vSelect
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            role: 'reservation',
            pageLength: 10,
            dir: false,
            pages: ['10', '20', '50'],
            filterVisible: false,
            columns: [
                {
                    label: 'Azonosító',
                    field: 'id',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Az. szerinti szűrés',
                    },
                },
                {
                    label: 'Iktatási szám',
                    field: 'reg_num',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Iktatási szám szerinti szűrés',
                    },
                },
                {
                    label: 'Kezdő dátum',
                    field: 'start_date',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Kezdő dátum szerinti szűrés',
                    },
                },
                {
                    label: 'Befejező dátum',
                    field: 'end_date',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Befejező dátum szerinti szűrés',
                    },
                },
            ],
            rows: [],
            page: 1,
            sortBy: {
                field: 'reg_num',
                type: 'desc',
            },
            columnFilters: [],
            filters: {
                'month': '',
                'dateFrom': '',
                'dateTo': '',
            },
            dataFromServer: [],
            monthOptions: [],
            forceStatusColumn: false,
            loading: false,
            labels,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
        isTableFiltered() {

            if (this.filters.month !== '' || this.filters.dateFrom !== '' || this.filters.dateTo !== '') {
                return true
            }

            return Object.keys(this.columnFilters).length > 0;
        }
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }

        this.loading = true

        if (typeof this.$route.query.onlyTodo !== "undefined") {
            this.onlyTodo = true
            this.forceStatusColumn = true
        }

        this.$store.dispatch('fetchFishingSpotsForTableFilter').then(fishingSpotFilterDropdownItems => {
            const fishingSpots = [
                {
                    label: 'Horgászhely',
                    field: 'fishing_spot_id',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Horgászhely szerinti szűrés',
                        filterDropdownItems: fishingSpotFilterDropdownItems
                    },
                },
            ]

            this.columns = [...this.columns, ...fishingSpots]
        }).finally(() => {
            if (!this.userId) {
                const users = [
                    {
                        label: 'Felhasználó',
                        field: 'user_id',
                        sortable: true,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Felhasználó szerinti szűrés',
                        },
                    },
                ]

                this.columns = [...this.columns, ...users]
            }

            if (!this.onlyTodo || Boolean(this.onlyTodo) !== true || this.userId || this.forceStatusColumn) {
                const statuses = [
                    {
                        label: 'Státusz',
                        field: 'status',
                        sortable: true,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Státusz szerinti szűrés',
                            filterDropdownItems: [
                                {
                                    value: 'request',
                                    text: 'Kérelem'
                                },
                                {
                                    value: 'conditional',
                                    text: 'Feltételes'
                                },
                                {
                                    value: 'confirmed',
                                    text: 'Visszaigazolt'
                                },
                                {
                                    value: 'canceled',
                                    text: 'Lemondott'
                                },
                                {
                                    value: 'deleted',
                                    text: 'Törölt'
                                }
                            ]
                        },
                    }
                ]

                this.columns = [...this.columns, ...statuses]
            }

            if (this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
                const actions = [
                    {
                        label: this.$t('table.header.actions'),
                        field: 'action',
                        sortable: false,
                        width: '10rem',
                    },
                ]

                this.columns = [...this.columns, ...actions]
            }
        })

        this.monthOptions = this.$helpers.getLastXMonthsForSelect()

        this.initEntities()
    },
    methods: {
        initEntities() {
            this.loading = true

            if (this.onlyTodo && Boolean(this.onlyTodo) === true) {
                this.columnFilters = {
                    onlyTodo: true,
                }
            }

            if (this.userId && this.userId > 0) {
                this.columnFilters = {
                    user_id: this.userId,
                }
            }

            const searchParams = {
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: this.columnFilters,
                sort: this.sortBy,
                intervalStart: !this.filters.dateFrom ? null : this.filters.dateFrom,
                intervalEnd: !this.filters.dateTo ? null : this.filters.dateTo,
            }

            this.$store.dispatch('fetchReservations', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            }).finally(() => {
                this.loading = false
            })
        },
        onRowClick(params) {
            if (this.$acl.canEditPermission(this.role))
                this.$router.push({name: 'show-reservation', params: {id: params.row.id}})
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        newEntity() {
            this.$router.push({name: 'add-reservation'})
        },
        deleteEntity(id, event) {
            this.$bvModal
                .msgBoxConfirm('Biztosan törölni szeretnéd a foglalást?', {
                    title: 'Jóváhagyás',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Igen',
                    cancelTitle: 'Nem',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        // axios
                        this.$store.dispatch('deleteReservation', id).then(() => {
                            this.initEntities(1)
                            this.$helpers.showSuccessToast(`Sikeres törlés (${id})`)
                        })
                    }
                })
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initEntities()
        },
        onColumnFilter(params) {
            this.page = 1
            this.columnFilters = params.columnFilters
            this.initEntities()
        },
        monthInputChanged() {
            if (!this.filters.month) {
                this.filters.month = ''
            }

            this.filters.dateFrom = ''
            this.filters.dateTo = ''

            if (this.filters.month) {
                this.filters.dateFrom = this.filters.month.startDate
                this.filters.dateTo = this.filters.month.endDate
            }
        },
        toggleFilters() {
            this.filterVisible = !this.filterVisible
        },
        resetFilters() {
            this.filters = {
                'month': '',
                'dateFrom': '',
                'dateTo': '',
            }

            this.columnFilters = []

            this.$refs['reservationsTable'].reset();
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
